import React from 'react'
import { who_we_are_1 } from '../assets'

function WhoWeAre() {
    return (
        <div style={{
            backgroundImage: `url(${who_we_are_1})`,
        }}
            className={`bg-[#F5F2F2] xs:h-[790px] py-8 h-[550px] bg-cover bg-no-repeat`}
        >
            <div className='grid h-full grid-cols-1 px-8 sm:grid-cols-3 md:grid-cols-2 xs:px-16'>
                <div className='flex flex-col justify-center h-full col-span-1 gap-6 sm:col-span-2 md:col-span-1 xs:gap-10 lg:pl-44'>
                    <h2 className='font-poppins font-bold text-[24px] xs:text-[28px] lg:text-[37px] leading-[54.47px]'>
                        Who We Are
                    </h2>
                    <div className='flex flex-col gap-4'>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            A dedicated team of innovators, engineers, and industry
                            experts committed to revolutionizing the way businesses verify invoices.
                        </p>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            We are aware of the challenges faced in the shipping industry and
                            thus crafted iVerifAI to streamline and optimize the verification process,
                            ensuring every transaction aligns flawlessly with predetermined rate cards.
                        </p>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            Our solution harnesses the power of AI to reduce human errors,
                            providing precise and reliable invoice verification before rolling out to clients,
                            thereby avoiding miscommunication or multiple follow-ups and explanations later.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhoWeAre