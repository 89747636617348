import React, { useRef } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from './components';
import Home from './pages/Home';
import About from './pages/About';
import Solutions from './pages/Solutions';
import { ToastContainer } from 'react-toastify';
// import Blogs from './pages/Blogs';

const App = () => {
  
  const contactRef = useRef(null);

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout scrollToContact={scrollToContact} />}>
          <Route index element={<Home contactRef={contactRef} />} />
          <Route path="/about-us" element={<About contactRef={contactRef} />} />
          <Route path="/solutions" element={<Solutions contactRef={contactRef}/>} />
          {/* <Route path="/blog" element={<Blogs />} /> */}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App

// import React from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import "./styles.css";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import ComingSoon from "./pages/ComingSoon";
// import TermsConditions from "./pages/TermsConditions";

// const App = () => {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<Layout />}>
//           <Route path="/" element={<ComingSoon />} />
//           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//           <Route path="/terms-conditions" element={<TermsConditions />} />
//           <Route path="/blog" element={<Blogs />} />
//           <Route path="*" element={<ComingSoon />} />
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App