import React from 'react';
import styles from '../style';
import {
    Hero,
    Features,
    WhyIverifai,
    ContactUs,
    Footer,
    Vector1,
    Vector2,
    Security
} from '../components';

const Home = ({ contactRef }) => {
    return (
        <div>
            <div className={`bg-white ${styles.flexStart}`}>
                <div className={`${styles.boxWidth} xs:pt-16 xs:pb-1 pb-8`}>
                    <Hero />
                </div>
            </div>

            <div className={`bg-primary ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <Vector1 />
                    <Vector2 />
                    <Features />
                    <WhyIverifai />
                    <Security />
                    {/* <Signup /> */}
                    <div ref={contactRef}>
                        <ContactUs />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Home