import React from 'react'
import { what_we_do_2 } from '../assets'

function WhatWeDo() {
    return (
        <div className={`bg-[#F5F2F2] pt-8 pb-24`}>
            <div className='grid h-full grid-cols-1 gap-4 px-8 md:grid-cols-2 xs:px-16'>
                <div className='flex flex-col justify-center h-full col-span-1 gap-6 xs:gap-10 lg:pl-44'>
                    <h2 className='font-poppins font-bold text-[24px] xs:text-[28px] lg:text-[37px] leading-[54.47px]'>
                        What We Do
                    </h2>
                    <div className='flex flex-col gap-4'>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            iVerifAI empowers businesses with a comprehensive AI-powered invoice verification system.
                            It simplifies onboarding, streamlines rate card management, automates invoice processing,
                            and enhances accuracy through advanced data extraction and matching capabilities.
                        </p>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            With iVerifAI, businesses can confidently verify transactions,
                            eliminate discrepancies, and accelerate their verification processes.
                        </p>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            Our solution also minimizes human errors and ensures that invoices are
                            thoroughly checked before being sent to clients, preventing miscommunication
                            and reducing the need for multiple follow-ups and explanations.
                        </p>
                    </div>
                </div>
                <div className='hidden col-span-1 mt-10 md:block'>
                    <img src={what_we_do_2} className='' alt='image1' />
                </div>
            </div>
        </div>
    )
}

export default WhatWeDo