import React from 'react'
import { ourVision } from '../assets'


function OurVision() {
    return (
        <div className={`bg-[#033469]`}
        >
            <div className='grid h-full grid-cols-1 md:grid-cols-2 md:py-0 md:pt-9 py-28 md:px-0 xs:px-6'>
                <div className='hidden col-span-1 md:block'>
                    <img src={ourVision} className='' alt='image1' />
                </div>
                <div className='flex flex-col justify-center h-full col-span-1 gap-6 xs:gap-10 text-white px-8 md:pr-12 lg:pr-[190px]'>
                    <h2 className='font-poppins font-bold text-[24px] xs:text-[28px] lg:text-[37px] leading-[54.47px]'>
                        Our Vision
                    </h2>
                    <div className='flex flex-col gap-4'>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            To become the global standard for AI-powered invoice verification,
                            setting new benchmarks in accuracy, efficiency, and transparency.
                            We are working towards creating a world where businesses across all industries
                            can make the best use of advanced AI technology to achieve swift and error-free
                            invoice processing, thereby enhancing their operational efficiency and client satisfaction.
                        </p>
                        {/* <p className='text-base font-normal xs:text-xl font-open_sans'>
                            iVerifAI simplifies onboarding, streamlines rate card management,
                            automates invoice processing and enhances accuracy through advanced data extraction and matching capabilities.
                        </p>
                        <p className='text-base font-normal xs:text-xl font-open_sans'>
                            With iVerifAI, businesses can confidently verify transactions,
                            eliminate discrepancies, and accelerate their verification process.
                        </p>
                        <p className='text-base font-normal xs:text-xl font-open_sans'>
                            Experience the future of invoice verification with iVerifAI.
                        </p>
                        <p className='text-base font-normal xs:text-xl font-open_sans'>
                            Sign up today and unlock the power of AI technology to streamline your verification processes.
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurVision