import React from 'react'
import styles from '../style'
import { aboutHeroBg } from '../assets'
import {
    ContactUs,
    Footer,
    Pricing,
    SolutionAtGlance
} from '../components';

function Solutions({ contactRef }) {
    return (
        <div>
            <div className={`bg-[#033469] ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <section id='home'
                        style={{
                            backgroundImage: `url(${aboutHeroBg})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: '50% 50%',
                        }}
                        className={`md:h-[570px] h-[550px] ${styles.paddingY}`}
                    >
                        <div className={`flex gap-8 xs:gap-10 flex-col items-center justify-center text-center text-white py-24 xs:py-32 ${styles.paddingX}`}>
                            <h1 className='font-semibold text-white font-poppins xs:text-[28px] text-[24px] px-0 lg:px-32 md:text-[37px] md:leading-[49.77px] xs:leading-[44px] leading-[38px]'>
                                Discover the power of streamlined invoicing and reconciliation
                                at your fingertips with iVerifai
                            </h1>
                            <p className='font-normal text-white font-poppins txet-[20px] xs:text-[25px] leading-[24px] xs:leading-[29.63px] px-0 lg:px-24'>
                                designed to handle all your administrative needs with precision and efficiency.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
            <div className={`bg-primary ${styles.flexStart}`}>
                <div className={`${styles.boxWidth}`}>
                    <SolutionAtGlance />
                    <Pricing />
                    <div ref={contactRef}>
                        <ContactUs />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Solutions