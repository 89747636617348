import React from 'react'
import styles from '../style'
import { card1, card2, card3, card4, card6, card7 } from '../assets';

const Cards = [
  {
    title: 'Vendor Registration',
    info: 'Simplify your onboarding process by allowing vendors to easily register on the platform.',
    icon: card1,
    bg: 'bg-white'
  },
  {
    title: 'Rate Card Management',
    info: 'Empower vendors to easily upload and manage their rate cards effortlessly. iVerifAI centralizes all the information, making it accessible and easy to update.',
    icon: card2,
    bg: 'bg-primary'
  },
  {
    title: 'Invoice Upload',
    info: 'Invoices can be seamlessly uploaded to the system, eliminating the need for manual data entry. We accept a variety of invoice formats, thus making our solution a versatile one for all diverse needs.',
    icon: card3,
    bg: 'bg-white'
  },
  {
    title: 'Data Extraction',
    info: 'Our advanced AI technology extracts crucial data from invoices, minimizing the chances of human error. iVerifAI ensures that all relevant information is captured accurately and efficiently.',
    icon: card4,
    bg: 'bg-white'
  },
  {
    title: 'Matching Against Rate Card',
    info: 'The heart of iVerifAI – the system intelligently matches extracted data from invoices against your uploaded rate cards. This automated process enhances accuracy and ensures that every transaction aligns with your predetermined pricing structure.',
    icon: card2,
    bg: 'bg-primary'
  },
  {
    title: 'Verification',
    info: `iVerifAI doesn't just stop at matching – it verifies each transaction, providing you with the confidence that your shipments are billed correctly. So you no longer have to deal with any discrepancies.`,
    icon: card6,
    bg: 'bg-white'
  },
  {
    title: 'Automatic Training',
    info: 'Over time, iVerifAI learns from your transactions. The system evolves and adapts, becoming more accurate and efficient with each cycle. Experience the power of AI that gets smarter as it works for you.',
    icon: card7,
    bg: 'bg-white'
  },
];

function Features() {
  return (
    <div className={`bg-[#033469] ${styles.paddingX} py-6`}>
      <h3 className='font-bold text-white font-poppins text-[24px] xs:text-[28px] lg:text-[37px] text-center'>
        Features
      </h3>
      <p className='text-white font-poppins text-[20px] xs:text-[25px] text-center leading-[27.24px] py-1'>
        iVerifAI is powered with features that will benefit your business.
      </p>
      <div className='grid grid-cols-1 px-2 py-6 lg:px-28 gap-x-8 gap-y-8 sm:grid-cols-2 md:grid-cols-3'>
        {Cards.map((item, index) => (
          <div key={index} className={`flex col-span-1 ${item.bg} px-6 py-6`}>
            <div className='grid grid-cols-6 gap-x-2'>
              <div className='col-span-1 py-1'>
                <img src={item.icon} alt='img2' />
              </div>
              <div className='col-span-5'>
                <h4 className={`font-poppins font-bold text-[20px] pb-1 leading-6 ${item.bg.includes('primary') ? 'text-white' : 'text-black'}`}>
                  {item.title}
                </h4>
                <p className={`font-open_sans font-normal text-[18px] text-left leading-[22px] ${item.bg.includes('primary') ? 'text-white' : 'text-[#666666]'}`}>
                  {item.info}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Features