import React from 'react'
import { why_we_started } from '../assets'


function WhyWeStarted() {
    return (
        <div className={`bg-[#033469]`}
        >
            <div className='grid h-full grid-cols-1 md:grid-cols-2 md:py-0 md:pt-9 py-28 md:px-0 xs:px-6'>
                <div className='hidden col-span-1 md:block'>
                    <img src={why_we_started} className='' alt='image1' />
                </div>
                <div className='flex flex-col justify-center h-full col-span-1 gap-6 xs:gap-10 text-white px-8 md:pr-12 lg:pr-[190px]'>
                    <h2 className='font-poppins font-bold text-[24px] xs:text-[28px] lg:text-[37px] leading-[54.47px]'>
                        Why We Started
                    </h2>
                    <div className='flex flex-col gap-4'>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            We understand the complexities and inefficiencies that plague the invoice
                            verification process in various industries, especially supply chain.
                        </p>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            Our journey began with a vision to simplify and automate these processes,
                            allowing businesses to focus on what they do best. We believe in the transformative
                            power of AI to bring precision, speed, and transparency to invoice verification.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyWeStarted