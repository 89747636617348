import React, { useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import { close, menu,iverifailogo } from '../assets'



import { navLinks } from '../constants'

function Navbar({ scrollToContact }) {
  const location = useLocation();
  const { pathname } = location;
  const [toggle, setToggle] = useState(false);

  return (
    <nav className='flex items-center justify-between w-full pt-3 pb-2 navbar'>
      <img src={iverifailogo} alt='i-verifai' className='w-32 h-12 p-4 xs:w-60 xs:h-20' />

      <ul className='items-center justify-end flex-1 hidden list-none md:flex'>
        {
          navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`font-poppins font-medium ${pathname === '/' && nav.id === 'home' && 'text-primary'} ${pathname === `/${nav.id}` ? 'text-primary' : 'text-black'} cursor-pointer text-[14px] mr-10`}
            >
              <Link to={`/${nav.id === 'home' ? '' : nav.id}`}>
                {nav.title}
              </Link>
            </li>
          ))
        }
        <li
          className={`font-poppins font-medium cursor-pointer text-[14px] mr-10`}
          onClick={scrollToContact}
        >
          Contact
        </li>
      </ul>
      <div className='flex items-center justify-end flex-1 md:hidden'>
        {/* <div className='mr-4'>
          <img src={cart} alt='cart' className='xs:w-[24px] xs:h-[24px]' />
        </div> */}
        <img
          src={toggle ? close : menu}
          alt='menu'
          className='w-[28px] h-[28px] object-contain'
          onClick={() => setToggle((prev) => !prev)}
        />
        <div className={`${toggle ? 'flex' : 'hidden'} p-6 bg-white absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}>
          <ul className='flex flex-col items-center justify-end flex-1 list-none'>
            {
              navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-normal cursor-pointer text-[16px] ${pathname === '/' && nav.id === 'home' && 'text-primary'} ${pathname === `/${nav.id}` ? 'text-primary' : 'text-black'} mb-4`}
                  onClick={() => setToggle(false)}
                >
                  <Link to={`/${nav.id === 'home' ? '' : nav.id}`}>
                    {nav.title}
                  </Link>
                </li>
              ))
            }
            <li
              className={`font-poppins font-normal cursor-pointer text-[16px]`}
              onClick={() => { scrollToContact(); setToggle(false) }}
            >
              Contact
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
