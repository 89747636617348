import { Outlet } from "react-router-dom";
import styles from "../style";
import Navbar from "./Navbar";

const Layout = ({ scrollToContact }) => {
    return (
        <div className='w-full overflow-hidden bg-white'>
            <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                <div className={`${styles.boxWidth}`}>
                    <Navbar scrollToContact={scrollToContact} />
                </div>
            </div>
            <Outlet />
        </div>
    )
};

export default Layout;