import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { contactusBg } from '../assets'
import { Success } from '../constants/alerts';
const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_USER_ID } = process.env

function ContactUs() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: REACT_APP_USER_ID,
      })
      .then(() => {
        Success('Send Successfully!!')
        e.target.reset();
      },
        (error) => {
          Error(`Error: ${error.text}`)
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2'>
      <div className='col-span-1 px-8 py-10 xs:py-16 md:pl-16 md:px-0 md:pr-32 bg-primary'>
        <h2 className='font-poppins font-bold text-[20px] xs:text-[25px] lg:text-[37px] text-white leading-[25px] py-4 xs:py-9'>
          Contact Us
        </h2>
        <div className='border-white border-[3px] w-[48px] mb-11'></div>
        <div>
          <form ref={form} onSubmit={sendEmail}>
            <div className='grid grid-cols-1 pb-4 xs:grid-cols-2 gap-x-6 gap-y-4'>
              <input
                type='text'
                className='col-span-1 px-[18px] py-[13px] outline-none'
                placeholder='First Name'
                name='first_name'
                required
              />
              <input
                type='text'
                className='col-span-1 px-[18px] py-[13px] outline-none'
                placeholder='Last Name'
                name='last_name'
                required
              />
              <input
                type='email'
                className='col-span-1 px-[18px] py-[13px] outline-none'
                placeholder='Email'
                name='email'
                required
              />
              <input
                type='text'
                className='col-span-1 px-[18px] py-[13px] outline-none'
                placeholder='Mobile No.'
                name='mobile_no'
                required
              />
            </div>
            <textarea
              rows={6}
              className='px-[18px] w-full py-[13px] outline-none'
              placeholder='Message'
              name='message'
              required
            />
            <div className='flex justify-center pt-2 md:justify-end'>
              <button type='submit' className='text-white border-white border-[4px] text-[14px] font-open_sans font-semibold py-2 px-16 leading-[16.59px]'>
                SEND MESSAGE
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='hidden col-span-1 sm:block'>
        <img src={contactusBg} alt='bgImage' className='w-full h-full' />
      </div>
    </div>
  )
}

export default ContactUs